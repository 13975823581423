import _ from "lodash";

export const mapItemToBufferData = (item) => {
  const bufferData = {
    zones: {
      green: {
        units: item.green_zone,
      },
      yellow: {
        units: item.yellow_zone,
      },
      red: {
        units: item.red_zone,
      },
      grey: {
        units: item.grey_zone,
      },
    },
    bufferParameters: {
      minimum_order_quantity: item.minimum_order_quantity,
      decoupled_lead_time: item.supply_lead_time,
      average_daily_usage: item.average_daily_usage,
      lead_time_factor: item.lead_time_factor,
      variability_factor: item.variability_factor,
    },
    netFlow: {
      on_hand_stock: item.on_hand_stock,
      transit_stock: item.transit_stock,
      open_demand: item.open_demand_orders,
      net_flow_eq: item.net_flow_equation,
      multiple_order_quantity: item.multiple_order_quantity,
    },
    analytics: {
      avg_on_hand_stock: _.isNumber(item.red_zone) ? item.red_zone + 0.5 * item.green_zone : null,
      net_flow_percentage: item.net_flow_priority * 100,
      current_on_hand_percentage: item.current_on_hand_alert * 100,
    },
    partId: item.location_code_concatenation,
    onHandStock: item.on_hand_stock,
    coloredCells: {
      net_flow_percentage: findBufferZone(item.net_flow_equation, item),
      current_on_hand_percentage: findBufferZone(
        item.on_hand_stock,
        item,
        true
      ),
    },
  };
  if (!_.isEmpty(Object.values(bufferData).filter((value) => _.isNil(value)))) {
    return null;
  }
  return bufferData;
};

const findBufferZone = (value, item, offset = false) => {
  if (offset) {
    if (value <= 0) {
      return "black";
    } else if (value <= item.top_of_grey) {
      return "grey";
    } else if (value <= (item.top_of_red / 2)) {
      return "red";
    } else if (value <= item.top_of_red) {
      return "yellow";
    } else if (value <= item.top_of_yellow) {
      return "green";
    } else if (value > item.top_of_yellow) {
      return "blue";
    }
  } else {
    if (value <= 0) {
      return "black";
    } else if (value <= item.top_of_grey) {
      return "grey";
    } else if (value <= item.top_of_red) {
      return "red";
    } else if (value <= item.top_of_yellow) {
      return "yellow";
    } else if (value <= item.top_of_green) {
      return "green";
    } else if (value > item.top_of_green) {
      return "blue";
    }
  }
};

export const conditionallyColoredBufferQuantities = [
  "net_flow_percentage",
  "current_on_hand_percentage",
];

export const percentageQuantities = [
  "net_flow_percentage",
  "current_on_hand_percentage",
];

export const getSelectedRowBatchData = (selectedRow, items) => {
  if (
    _.isNil(items.batchData) ||
    _.isNil(selectedRow) ||
    _.isNil(selectedRow.values) ||
    !_.has(items.batchData, selectedRow.values.location_code_concatenation)
  ) {
    return [];
  }

  return items.batchData[selectedRow.values.location_code_concatenation];
};
