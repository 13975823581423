import React, { useEffect, useRef, useState, useCallback } from "react";
import {
    Button,
    Dropdown,
    Form,
    Modal,
    Grid,
    GridRow,
    GridColumn,
    Segment,
    Divider,
    Confirm,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import FormulaInput from "./FormulaInput";
import {
    doClearSingleCalculation,
    doDeleteDynamicField,
    doGetDynamicFields,
    doLoadMetadata,
    doSingleCalc,
    doUpdateDraftDynamicField,
    doResetDraftDynamicField,
    doSetDraftDynamicField,
} from '../../../actions/dynamicFields';
import _ from 'lodash';

const DynamicFieldsModalContent = ({
    // props
    crudOperationError,
    currentWorkspaceId,
    draftDynamicField,
    dynamicFieldItems,
    loadingCrudOperation,
    rowIds,
    singleCalcResult,

    // actions
    doClearSingleCalculation,
    doDeleteDynamicField,
    doGetDynamicFields,
    doLoadMetadata,
    doResetDraftDynamicField,
    doSetDraftDynamicField,
    doSingleCalc,
    doUpdateDraftDynamicField,
}) => {
    const { t } = useTranslation();
    
    // track whether any changes have occurred on the form
    const [isDirty, setIsDirty] = useState(false);
    const [title, setTitle] = useState('');
    const [isFieldNameDirty, setIsFieldNameDirty] = useState(false);
    const [fieldNameValue, setFieldNameValue] = useState('');
    const [formula, setFormula] = useState('');
    const [currentRowId, setCurrentRowId] = useState(null);
    const [currentDynamicFieldId, setCurrentDynamicFieldId] = useState(null);
    const [isNewFormActionPending, setIsNewFormActionPending] = useState(false);
    const [pendingEditDynamicFieldId, setPendingDynamicFieldId] = useState(0);
    const [resultText, setResultText] = useState('');
    const [isConfirmFormActionOpen, setIsConfirmFormActionOpen] = useState(false);
    const [isConfirmDeleteActionOpen, setIsConfirmDeleteActionOpen] = useState(false);
    const [isPendingDelete, setIsPendingDelete] = useState(false);
    const formulaOnChangeTimeout = useRef(0);

    useEffect(() => {
        let text = t("ordersTable.dynamicFields.example") + ": "
            + t("ordersTable.dynamicFields.record") + ": "
            + currentRowId;
        if (singleCalcResult && singleCalcResult.trim().length > 0) {
            text += ", " + t("ordersTable.dynamicFields.result") + ": " + singleCalcResult;
        }

        setResultText(text);
    }, [currentRowId, singleCalcResult, setResultText, t]);

    useEffect(() => {
        // initial component load
        doClearSingleCalculation();
        doResetDraftDynamicField();
        doLoadMetadata(currentWorkspaceId);
        doGetDynamicFields(currentWorkspaceId);
    }, [currentWorkspaceId, doClearSingleCalculation, doResetDraftDynamicField, doLoadMetadata, doGetDynamicFields]);

    useEffect(() => {
        if (_.isEmpty(formula)) {
            clearTimeout(formulaOnChangeTimeout.current);
            doClearSingleCalculation();
            return;
        }

        if (formulaOnChangeTimeout.current) {
            clearTimeout(formulaOnChangeTimeout.current);
        }

        formulaOnChangeTimeout.current = setTimeout(() => {
            doSingleCalc(currentWorkspaceId, formula, currentRowId);
        }, 1000);
    }, [
        currentWorkspaceId,
        doClearSingleCalculation,
        doSingleCalc,
        currentRowId,
        formula,
    ]);

    /*useEffect(() => {
        if (draftDynamicField.row_id && draftDynamicField.row_id.trim().length > 0) {
            setSingleRowOnlyChecked(true);
            setCurrentRowId(draftDynamicField.row_id);
        }
    }, [draftDynamicField])*/

    useEffect(() => {
        if (!rowIds || rowIds.length === 0) {
            return;
        }
        setCurrentRowId(rowIds[0].replace(/\s+/g, '_'));
    }, [rowIds, setCurrentRowId]);

    const resetForm = useCallback(() => {
        if (formulaOnChangeTimeout.current) {
            clearTimeout(formulaOnChangeTimeout.current);
        }
        setIsDirty(false);
        setIsFieldNameDirty(false);
        setTitle('');
        setFormula('');
        setFieldNameValue('');

        doClearSingleCalculation();
    }, [setIsDirty, setIsFieldNameDirty, setTitle, setFormula, setFieldNameValue, doClearSingleCalculation]);

    const setDynamicFieldItemToEdit = useCallback(() => {
        if (currentDynamicFieldId > 0) {
            const items = dynamicFieldItems.filter(item => item.id === currentDynamicFieldId);
            if (items.length > 0) {
                const dynamicFieldToEdit = _.clone(items[0]);
                doResetDraftDynamicField();
                resetForm();
                
                doSetDraftDynamicField(_.extend(dynamicFieldToEdit, {dynamic_field_id: dynamicFieldToEdit.id}));
                setFieldNameValue(dynamicFieldToEdit.name);
                setFormula(dynamicFieldToEdit.formula);
            }
        }
    }, [currentDynamicFieldId, doSetDraftDynamicField, dynamicFieldItems, doResetDraftDynamicField, resetForm]);

    useEffect(() => {
        doUpdateDraftDynamicField("name", fieldNameValue);
    }, [doUpdateDraftDynamicField, fieldNameValue]);
    
    useEffect(() => {
        doUpdateDraftDynamicField("formula", formula);
    }, [doUpdateDraftDynamicField, formula]);

    useEffect(() => {
        doUpdateDraftDynamicField("row_id", currentRowId);
    }, [doUpdateDraftDynamicField, currentRowId])

    const handleNewButtonClick = useCallback(() => {
        if (isDirty) {
            setIsNewFormActionPending(true);
            setPendingDynamicFieldId(0);
            setIsConfirmFormActionOpen(true);
            return;
        }

        setCurrentDynamicFieldId(null);
        doResetDraftDynamicField();
        resetForm();
    }, [isDirty, doResetDraftDynamicField, resetForm]);

    const handleConfirmFormAction = useCallback(() => {
        if (isNewFormActionPending) {
            setCurrentDynamicFieldId(null);
            doResetDraftDynamicField();
            resetForm();
    
            setIsNewFormActionPending(false);
        } else if (pendingEditDynamicFieldId > 0) {
            resetForm();
            setCurrentDynamicFieldId(pendingEditDynamicFieldId);
            setPendingDynamicFieldId(0);
        }

        setIsConfirmFormActionOpen(false);
    }, [doResetDraftDynamicField, isNewFormActionPending, pendingEditDynamicFieldId, resetForm]);

    const handleConfirmDeleteAction = useCallback(() => {
        if (!currentDynamicFieldId) {
            return;
        }
        setIsPendingDelete(true);
        doDeleteDynamicField(currentWorkspaceId, currentDynamicFieldId);
        setIsConfirmDeleteActionOpen(false);    
    }, [currentWorkspaceId, currentDynamicFieldId, doDeleteDynamicField]);

    useEffect(() => {
        if (!loadingCrudOperation && !crudOperationError) {
            if (isPendingDelete) {
                setIsPendingDelete(false);
                resetForm();
            }
        }
    }, [crudOperationError, loadingCrudOperation, isPendingDelete, resetForm, setIsPendingDelete]);

    const handleCancelPendingFormAction = useCallback(() => {
        setIsNewFormActionPending(false);
        setPendingDynamicFieldId(0);
        setIsConfirmFormActionOpen(false);
    }, [setIsConfirmFormActionOpen, setIsNewFormActionPending, setPendingDynamicFieldId]);

    const handleSelectDynamicFieldToEdit = (e, data) => {
        const newDynamicFieldId = data.value;
        if (!newDynamicFieldId || newDynamicFieldId <= 0) {
            return;
        }
        
        if (isDirty) {
            setIsNewFormActionPending(false);
            setPendingDynamicFieldId(newDynamicFieldId);
            setIsConfirmFormActionOpen(true);
            setCurrentDynamicFieldId(null);
            return;
        }

        if (newDynamicFieldId !== currentDynamicFieldId) {
            resetForm();
        }
        setCurrentDynamicFieldId(newDynamicFieldId);
    };

    useEffect(() => {
        if (!currentDynamicFieldId) {
            return;
        }
        setDynamicFieldItemToEdit();
    }, [currentDynamicFieldId, setDynamicFieldItemToEdit]);

    return (
        <>
            <Modal.Header>{t("ordersTable.dynamicFields.title")}</Modal.Header>
            <Modal.Content>
                <Grid columns={2} divided>
                    <GridRow>
                        <GridColumn width={10}>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <Form.Input
                                required
                                label={t("ordersTable.dynamicFields.fieldTitle")}
                                placeholder={t("ordersTable.dynamicFields.title")}
                                name="field-title"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setTitle(value);
                                    setIsDirty(true);

                                    // do not auto-determine if the field was manually changed or if in edit mode
                                    if (!isFieldNameDirty && !currentDynamicFieldId) {
                                        setFieldNameValue(value.toLowerCase().replace(/\W+/g, '_'));
                                    }

                                    doUpdateDraftDynamicField("title", value);
                                }}
                                value={draftDynamicField.title || title} />
                            <Form.Input
                                required
                                label={t("ordersTable.dynamicFields.fieldName")}
                                placeholder={"field_name"}
                                disabled={currentDynamicFieldId > 0}
                                name="field-name"
                                onChange={(e) => {
                                    setIsDirty(true);
                                    if (e.target === document.activeElement) {
                                        setFieldNameValue(e.target.value);
                                    }
                                }}
                                onKeyUp={() => setIsFieldNameDirty(true)}
                                onBlur={() => {
                                    if (_.isEmpty(fieldNameValue)) {
                                        setIsFieldNameDirty(false);
                                    }
                                }}
                                value={fieldNameValue} />
                            <Divider section />

                            <FormulaInput
                                onValueChange={value => setFormula(value)}
                                value={formula} />
                            <div><strong>{resultText}</strong></div>
                        </Form>
                        </GridColumn>

                        <GridColumn width={6}>
                            <Segment basic clearing>
                                <Button
                                    primary
                                    floated="right"
                                    onClick={handleNewButtonClick}>
                                        {t("ordersTable.dynamicFields.new")}
                                </Button>
                            </Segment>
                            <Segment basic clearing>
                                <Dropdown
                                    placeholder={t("ordersTable.dynamicFields.edit")}
                                    fluid
                                    search
                                    selection
                                    options={dynamicFieldItems.map(field => ({
                                        key: field.id,
                                        text: field.title,
                                        value: field.id
                                    }))}
                                    onChange={handleSelectDynamicFieldToEdit}
                                    value={currentDynamicFieldId}
                                    />
                                <Button
                                    negative
                                    floated="right"
                                    disabled={!currentDynamicFieldId || currentDynamicFieldId === 0}
                                    onClick={() => setIsConfirmDeleteActionOpen(true)}>
                                        {t("ordersTable.dynamicFields.delete")}
                                </Button>
                            </Segment>
                        </GridColumn>
                    </GridRow>
                </Grid>
                <Confirm
                    open={isConfirmFormActionOpen}
                    header={t("ordersTable.dynamicFields.pendingChanges")}
                    content={t("ordersTable.dynamicFields.confirmPendingChanges")}
                    onCancel={handleCancelPendingFormAction}
                    onConfirm={handleConfirmFormAction} />
                <Confirm
                    open={isConfirmDeleteActionOpen}
                    header={t("ordersTable.dynamicFields.pendingDelete")}
                    content={t("ordersTable.dynamicFields.confirmPendingDelete")}
                    onCancel={() => setIsConfirmDeleteActionOpen(false)}
                    onConfirm={handleConfirmDeleteAction} />
            </Modal.Content>
        </>
    )
};


DynamicFieldsModalContent.propTypes = {
    crudOperationError: PropTypes.bool,
    currentWorkspaceId: PropTypes.number.isRequired,
    draftDynamicField: PropTypes.object,
    dynamicFieldItems: PropTypes.array,
    loadingCrudOperation: PropTypes.bool,
    rowIds: PropTypes.array,
    singleCalcResult: PropTypes.string,

    doClearSingleCalculation: PropTypes.func,
    doDeleteDynamicField: PropTypes.func,
    doGetDynamicFields: PropTypes.func,
    doLoadMetadata: PropTypes.func,
    doResetDraftDynamicField: PropTypes.func,
    doSetDraftDynamicField: PropTypes.func,
    doSingleCalc: PropTypes.func,
    doUpdateDraftDynamicField: PropTypes.func,
};

DynamicFieldsModalContent.defaultProps = {
    crudOperationError: false,
    currentWorkspaceId: 0,
    draftDynamicField: {},
    dynamicFieldItems: [],
    loadingCrudOperation: false,
    rowIds: [],
    singleCalcResult: '',
    
    doClearSingleCalculation: _.noop,
    doDeleteDynamicField: _.noop,
    doGetDynamicFields: _.noop,
    doLoadMetadata: _.noop,
    doResetDraftDynamicField: _.noop,
    doSetDraftDynamicField: _.noop,
    doSingleCalc: _.noop,
    doUpdateDraftDynamicField: _.noop,
}

const mapStateToProps = (state) => {
    return {
        crudOperationError: state.dynamicFields.crudOperationError,
        currentWorkspaceId: state.workspace.currentWorkspace,
        draftDynamicField: state.dynamicFields.draftDynamicField,
        dynamicFieldItems: state.dynamicFields.dynamicFieldItems,
        loadingCrudOperation: state.dynamicFields.loadingCrudOperation,
        rowIds: state.items.itemIds,
        singleCalcResult: state.dynamicFields.singleCalcResult,

        doClearSingleCalculation: state.dynamicFields.doClearSingleCalculation,
        doDeleteDynamicField: state.dynamicFields.doDeleteDynamicField,
        doGetDynamicFields: state.dynamicFields.doGetDynamicFields,
        doLoadMetadata: state.dynamicFields.doLoadMetadata,
        doResetDraftDynamicField: state.dynamicFields.doResetDraftDynamicField,
        doSetDraftDynamicField: state.dynamicFields.doSetDraftDynamicField,
        doSingleCalc: state.dynamicFields.doSingleCalc,
        doUpdateDraftDynamicField: state.dynamicFields.doUpdateDraftDynamicField,
    }
};

export default connect(mapStateToProps, {
    doClearSingleCalculation,
    doDeleteDynamicField,
    doGetDynamicFields,
    doLoadMetadata,
    doResetDraftDynamicField,
    doSetDraftDynamicField,
    doSingleCalc,
    doUpdateDraftDynamicField,
})(DynamicFieldsModalContent);
