import {
  GET_LAYOUTS,
  SELECT_LAYOUT,
  SAVE_LAYOUT,
  DELETE_LAYOUT,
  EDIT_LAYOUT,
  SELECT_ROW,
  UPDATE_ORDER_CALCULATIONS_METADATA,
  BEGIN_TABLE_UPDATE,
  ABORT_TABLE_UPDATE,
  END_TABLE_UPDATE,
  RESET_ORDER_CALCULATIONS_METADATA,
  SET_NEW_CALCULATION_REQUIRED,
  SET_ORDER_ERRORS,
  SET_NOTIFICATION_PANEL_FILTER,
  SET_ORDER_TABLE_FILTERS,
  SELECT_ACTUAL_ORDER_CELL,
  SET_ORDER_TABLE_CONTAINER
} from "../actions/types";

import { BASIC_LAYOUT, ADVANCED_LAYOUT } from "../utils/constants";

const initialState = {
  currentLayout: { ...BASIC_LAYOUT },
  savedLayouts: [{ ...BASIC_LAYOUT }, { ...ADVANCED_LAYOUT }],
  loading: true,
  isCommitted: false,
  committedAt: null,
  ordersCalculationId: null,
  selectedRow: null,
  loadingUpdate: false,
  calendarEvents: null,
  groups: null,
  calendarEventsChanged: false,
  groupsChanged: false,
  historicalDemandChanged: false,
  orderErrors: [],
  notificationFilterArray: '',
  orderTableFilters: {"location_code_concatenation": []},
  selectedActualOrderCell: false,
  orderTableContainerWidth: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_NEW_CALCULATION_REQUIRED:
      return {
        ...state,
        calendarEventsChanged: payload.calendarEventsChanged,
        groupsChanged: payload.groupsChanged,
        historicalDemandChanged: payload.historicalDemandChanged,
      };

    case BEGIN_TABLE_UPDATE:
      return {
        ...state,
        loadingUpdate: true,
      };
    case ABORT_TABLE_UPDATE:
      return {
        ...state,
        loadingUpdate: false,
      };
    case END_TABLE_UPDATE:
      return {
        ...state,
        loadingUpdate: false,
      };
    case RESET_ORDER_CALCULATIONS_METADATA:
      return {
        ...state,
        loading: false,
        loadingUpdate: false,
        isCommitted: false,
        committedAt: null,
        ordersCalculationId: null,
        calendarEvents: null,
        groups: null,
      };
    case UPDATE_ORDER_CALCULATIONS_METADATA:
      return {
        ...state,
        loading: false,
        isCommitted: payload.isCommitted,
        committedAt: payload.committedAt,
        ordersCalculationId: payload.id,
        calendarEvents: payload.calendarEvents,
        groups: payload.groups,
      };
    case GET_LAYOUTS:
      return {
        ...state,
        loading: false,
        savedLayouts: [{ ...BASIC_LAYOUT }, { ...ADVANCED_LAYOUT }, ...payload],
      };
    case SELECT_LAYOUT:
      return {
        ...state,
        // payload = layout id
        currentLayout: state.savedLayouts.find(
          (layout) => layout.id === payload
        ),
        loading: false,
      };

    case SAVE_LAYOUT:
      return {
        ...state,
        savedLayouts: [...state.savedLayouts, payload],
        currentLayout: payload,
        loading: false,
      };

    case EDIT_LAYOUT:
      return {
        ...state,
        // payload = new layout object
        savedLayouts: state.savedLayouts.map((original) =>
          original.id.toString() === payload.id.toString() ? payload : original
        ),
        currentLayout: payload,
        loading: false,
      };

    case DELETE_LAYOUT:
      return {
        ...state,
        savedLayouts: state.savedLayouts.filter(
          (layout) => layout.id.toString() !== state.currentLayout.id.toString()
        ),
        loading: false,
      };

    case SELECT_ROW:
      return {
        ...state,
        selectedRow: payload,
        loading: false,
      };
    case SET_ORDER_ERRORS:
      return{
        ...state,
        orderErrors: payload,
      }
    case SET_NOTIFICATION_PANEL_FILTER:
        return {
          ...state,
          notificationFilterArray: payload,
        }
    case SET_ORDER_TABLE_FILTERS:
      return {
        ...state,
        orderTableFilters: payload,
      }
    case SELECT_ACTUAL_ORDER_CELL:
      return {
        ...state,
        selectedActualOrderCell: payload,
      }
    case SET_ORDER_TABLE_CONTAINER:
      return {
        ...state,
        orderTableContainerWidth: payload,
      }
    default:
      return state;
  }
}
