import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Header from "./Header/Header";
import Row from "./Row/Row";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary"
import { connect } from "react-redux";
import { changeOrderTableWidth } from "../../../actions/ordersTable";

import "./Table.css";

const Table = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  filterRows,
  rows,
  prepareRow,
  onSelectRow,
  editable,
  ordersCalculationId,
  selectedRow,
  setFilter,
  changeOrderTableWidth
}) => {

  const refContainer = useRef(null);
  useEffect(() => {
    if (!refContainer.current) return;
    // resize observer help us to know when order table container width has changed
    // this action hep us to interat with horizontal scroll bar in order table
    //  check doc: https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      // max scroll left is like maxScrollLeft in firefox but this property does not exist in chrome
      var maxScrollLeft = refContainer.current.scrollWidth - refContainer.current.clientWidth;
      changeOrderTableWidth(maxScrollLeft)
    });
    resizeObserver.observe(refContainer.current);
    return () => resizeObserver.disconnect(); // clean up 
  }, [changeOrderTableWidth]);


  return (
    
    <div className="container data-table" id="container-data-table" ref={refContainer}>
      <ErrorBoundary>
        <table className="ui  celled table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Header
                    setFilter={setFilter}
                    tableWideFilteredRows={filterRows}
                    key={column.id}
                    column={column}
                  ></Header>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => (
              <Row
                ordersCalculationId={ordersCalculationId}
                key={row.id}
                prepareRow={prepareRow}
                onSelectRow={onSelectRow}
                editable={editable}
                row={row}
                selected={selectedRow && selectedRow.id === row.id}
              />
            ))}
          </tbody>
        </table>
      </ErrorBoundary>
    </div >
  );
};

Table.propTypes = {
  onSelectRow: PropTypes.func,
  editable: PropTypes.bool,
  getTableProps: PropTypes.func,
  getTableBodyProps: PropTypes.func,
  headerGroups: PropTypes.array,
  filterRows: PropTypes.array,
  rows: PropTypes.array,
  prepareRow: PropTypes.func,
  ordersCalculationId: PropTypes.number,
  selectedRow: PropTypes.object,
  setFilter: PropTypes.func,
  changeOrderTableWidth: PropTypes.func
};

Table.defaultProps = {
  onSelectRow: _.noop,
  editable: true,
};

// export default Table;
export default connect(null, { changeOrderTableWidth })(Table);
