import {
  GET_ITEMS,
  BEGIN_ITEMS_FETCH,
  ABORT_ITEMS_FETCH,
  EDIT_ACTUAL_ORDER,
  SET_CURRENT_PAGE,
  SET_PAGE_SIZE,
  GET_BATCH_DATA,
  UPDATE_TABLE_ORDER
} from "../actions/types";

const initialState = {
  loadingFetch: true,
  loadingFetchBatchData: true,
  items: [],
  batchData: null,
  pageIndex: 0,
  pageSize: 50,
  // a list of location code - material concatentations (IDs)
  itemIds: []
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENT_PAGE:
      return {
        ...state,
        pageIndex: payload,
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: payload,
      };
    case GET_ITEMS:
      return {
        ...state,
        items: payload,
        itemIds: payload.map((payloadItem) => payloadItem.location_code_concatenation),
        loadingFetch: false,
      };
    case GET_BATCH_DATA:
      return {
        ...state,
        batchData: payload,
        loadingFetchBatchData: false,
      };
    case EDIT_ACTUAL_ORDER:
      return {
        ...state,
        loadingFetch: false,
        items: state.items.map((item) =>
          item.location_code_concatenation ===
          payload.location_code_concatenation
            ? payload
            : item
        ),
        itemIds: state.items.map((item) => item.location_code_concatenation),
      };
    case BEGIN_ITEMS_FETCH:
      return {
        ...state,
        loadingFetch: true,
      };
    case ABORT_ITEMS_FETCH:
      return {
        ...state,
        loadingFetch: false,
        items: [],
        itemIds: [],
      };
    case UPDATE_TABLE_ORDER:
      return{
        ...state,
        items: payload,
        itemIds: payload.map((payloadItem) => payloadItem.location_code_concatenation),
      }
    default:
      return state;
  }
}
